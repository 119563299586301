// import libraries
import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "@react-pdf-viewer/core";

// import CSS file for styling
import "./textviewer.css";

const TextViewerComponent = ({
  selectedfilevalename,
  documenturl,
  onSelectedTextChange,
  selectedId,
}) => {
  const [text, setText] = useState("");
  const textRef = useRef(null);

  const getFile = async () => {
    try {
      const response = await fetch(documenturl);
      if (!response.ok) {
        throw new Error("Failed to fetch text file");
      }
      const data = await response.text();
      setText(data);
    } catch (error) {
      console.error("Error fetching file:", error);
      // Handle error, e.g., display an error message to the user
    }
  };
  useEffect(() => {
    console.log("documenturl", documenturl);
    // Fetch the .txt file contents
    if (documenturl && typeof documenturl) {
      getFile();
    }
    if (selectedId && !documenturl) {
      setText("");
    }
  }, [documenturl, selectedId]);

  useEffect(() => {
    // Add an onmouseup event listener to the text element
    const textElement = textRef.current;
    if (textElement) {
      textElement.addEventListener("mouseup", () => {
        // Get the selected text
        const selectedText = window.getSelection().toString();
        // Print the selected text to the console
        if (selectedText != "") {
          onSelectedTextChange(selectedText); // Call the function to pass the selected text
        }
      });
    }

    // Return a cleanup function to remove the event listener
    return () => {
      if (textElement) {
        textElement.removeEventListener("mouseup", () => {
          // Get the selected text
          const selectedText = window.getSelection().toString();

          // Print the selected text to the console
        });
      }
    };
  }, [textRef]);

  return (
    <div style={{ height: "100%" }}>
      <div className="pdf-name">{selectedfilevalename?.file_name}</div>
      {text ? (
        <div className="text-file-main-contatiner" ref={textRef}>
          {text}
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default TextViewerComponent;
