import React, { useState } from "react";
import DocuDivelog from "../../assets/images/DocDive_logo.jpg";
import { Link, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import { Spinner } from "reactstrap";
import { Eye, EyeOff } from "lucide-react";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Extracting parameters from the URL
  const user = searchParams.get("user");
  const vt = searchParams.get("vt");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmation, setShowConfirmation] = useState({
    isReset: false,
    message: "",
  });
  const { mutate, isLoading } = useMutation(
    () =>
      authDataObject.ResetPassword({
        vt: vt,
        password: password,
      }),
    {
      onSuccess: (data) => {
        if (data.status === 201) {
          setShowConfirmation({
            isReset: true,
            message: "Password has been reset successfully.",
          });
        }
      },
      onError: () => {
        setShowConfirmation({
          isReset: true,
          message: "Something went wrong.",
        });
      },
    }
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        border: "1px solid black",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          gap: "50px",
          width: "30%",
          height: "55%",
          padding: "20px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
        }}
      >
        <Link to="/">
          <img
            src={DocuDivelog}
            alt="docudivelogo"
            style={{
              width: "200px",
              height: "50px",
              cursor: "pointer",
            }}
          />
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <>
            <span
              style={{
                fontSize: "20px",
                alignSelf: showConfirmation.isReset ? "center" : "flex-start",
                margin: showConfirmation.isReset ? "0px 10%" : "0px 15%",
              }}
            >
              {showConfirmation.isReset
                ? showConfirmation.message
                : "Reset your DocuDive password"}
            </span>
            {!showConfirmation.isReset && (
              <>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    alignSelf: "flex-start",
                    margin: "0px 15%",
                  }}
                >
                  {user}
                </span>
                <div className={`password-style`}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="password-input"
                    placeholder="Enter New Password"
                    name="password"
                    style={{
                      padding: "0 10px",
                    }}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={() => togglePasswordVisibility()}
                  >
                    {showPassword ? <Eye /> : <EyeOff />}
                  </button>
                </div>
                <button
                  className="Emailbutton-container"
                  type="button"
                  onClick={() => {
                    mutate();
                  }}
                  disabled={password.length <= 0 || isLoading}
                >
                  {isLoading ? (
                    <Spinner style={{ width: "1rem", height: "1rem" }} />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </>
            )}
          </>
          <Link to="/">
            <span
              className={`forgot-pw-link ${isLoading ? "disabled" : ""}`}
              style={{ marginTop: 5 }}
            >
              Go back to login
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
