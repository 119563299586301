import { format } from "date-fns";
import React, { useState } from "react";

const MonthPicker = ({ min, max, onMonthChange }) => {
  const curMonth = format(new Date(), "yyyy-MM");

  const [selectedMonth, setSelectedMonth] = useState(curMonth);

  const handleMonthChange = (event) => {
    console.log(event.target.value);
    setSelectedMonth(event.target.value);
    onMonthChange(event.target.value);
  };

  return (
    <div
      style={{
        marginTop: "11px",
        marginBottom: "11px",
      }}
    >
      <input
        type="month"
        id="monthPicker"
        name="monthPicker"
        min={min ?? "2023-01"}
        max={max ?? curMonth}
        value={selectedMonth ?? curMonth}
        style={{
          height: "40px",
          borderRadius: "4px",
          border: "1px solid #d9d9d9",
          backgroundColor: "#f2f2f2",
          paddingLeft: "8px",
          paddingRight: "8px",
          outline: "none",
          cursor: "pointer",
        }}
        onChange={(e) => handleMonthChange(e)}
      />{" "}
    </div>
  );
};

export default MonthPicker;
