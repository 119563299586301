import { useEffect, useState } from "react";
import authDataObject from "../../../services/Auth_Controller/Auth_Controller";
import { Spinner } from "reactstrap";
import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";

const BillingHistory = () => {
  const [invoice, setInvoice] = useState([]);

  const { data: billingData, isFetching } = useQuery(
    [`billing-history`],
    authDataObject.getInvoice
  );
  useEffect(() => {
    if (billingData) {
      setInvoice(billingData.data.details.receipts);
    }
  }, [billingData]);

  return (
    <div>
      {isFetching && (
        <div
          style={{
            height: "85%",
            width: "90%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: "1050",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <Spinner
            size={"xl"}
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />{" "}
          <span
            style={{
              color: "#6c757d",
            }}
          >
            Please wait...
          </span>
        </div>
      )}
      <h1 className="subtitle">Billing history</h1>
      <div style={{ marginTop: 8 }}>
        <span className="belowstyle">
          Showing invoices within the past 12 months.
        </span>
      </div>

      <div className="tableheadingborder">
        <div className="tablegeading">Receipt Number</div>
        <div
          className="tablegeading"
          style={{
            textAlign: "center",
          }}
        >
          Status
        </div>
        <div
          className="tablegeading"
          style={{
            textAlign: "center",
          }}
        >
          Amount
        </div>
        <div
          className="tablegeading"
          style={{
            textAlign: "center",
          }}
        >
          Processed on
        </div>
        <div
          className="tablegeading"
          style={{
            textAlign: "center",
          }}
        >
          Link
        </div>
      </div>

      {invoice?.length > 0 ? (
        invoice?.map((obj) => (
          <div className="tableheadingdata">
            <div className="tablerowdata">{obj.receipt_number}</div>
            <div
              className="tablerowdata"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="billingStatus"
                style={{
                  backgroundColor:
                    obj.status === "succeeded" ? "#06BC9B" : "#F2624F",
                }}
              >
                {obj.status === "succeeded" ? "Successful" : "Failed"}
              </div>
            </div>
            <div
              className="tablerowdata"
              style={{
                textAlign: "center",
              }}
            >
              {getCurrency(obj?.currency)} {obj?.amount_paid}
            </div>
            <div
              className="tablerowdata"
              style={{
                textAlign: "center",
              }}
            >
              {obj.created_date
                ? format(parseISO(obj.created_date), "d MMM yyyy, HH:mm")
                : ""}
            </div>

            <div
              className="tablerowdata"
              style={{
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              <a
                style={{ color: "#100BE5" }}
                href={obj.receipt_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div>
          </div>
        ))
      ) : (
        <div className="tableheadingdata">
          <span className="tablerowdata">
            {isFetching ? "Loading..." : "No invoices"}
          </span>
        </div>
      )}
    </div>
  );
};
export default BillingHistory;

const getCurrency = (currency) => {
  switch (currency) {
    case "usd":
      return "$";
    case "eur":
      return "€";
    case "gbp":
      return "£";
    case "aud":
      return "A$";
    case "cad":
      return "C$";
    case "nzd":
      return "NZ$";
    case "sek":
      return "kr";
    case "dkk":
      return "kr";
    case "nok":
      return "kr";
    case "chf":
      return "CHF";
    case "jpy":
      return "¥";
    default:
      return "$";
  }
};
