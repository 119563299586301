import styled from "styled-components";
import { Menu, Settings } from "lucide-react";

export const Container = styled.div`
  position: sticky !important;
  top: 0 !important;
  /* background-color: #FFF; */
  z-index: 100 !important;
  height: 65px;
  display: flex;
  background-color: rgb(255, 255, 255);
  z-index: 4;
  position: relative;
  border-bottom: 1px solid rgb(226, 226, 226);
`;

export const SidebarIcon = styled(Menu)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 22px;
  left: 22px;
  cursor: pointer;
  &:hover {
    top: 18px;
    left: 18px;
    background-color: #e9e1e1; /* New background color on hover */
    border-radius: 25%; /* Make the background semi-circular on hover */
    width: 30px;
    height: 30px;
    padding: 6px;
  }
`;

export const SettingIconWrapper = styled.div`
  position: absolute;
  top: 22px;
  right: 42px; /* Adjust this value to increase or decrease the spacing between SettingIcon and RightTabs */
  cursor: pointer;
`;

export const SettingIcon = styled(Settings)`
  color: black;
  width: 20px;
  height: 20px;
`;

// Styled component for the right tabs
export const RightTabs = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin-right: 20px;
  color: #0a0909;
  text-align: center;
  font-family: League Spartan;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Tab = styled.div`
\  margin-left: 30px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  color: #0A0909;
  text-align: center;
  font-family: League Spartan;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 0px !important;
  transition: margin-bottom 0.4s ease-in-out;
  &:hover {
  margin-bottom : 15px !important;
  transition: margin-bottom 0.4s ease-in-out;
  } 
`;

export const LogoImage = styled.img`
 margin-left : 100px
  height: 35px;
  width: 128px;
  position: absolute;
  top: 50%;
  left: 28px; /* Adjust the left value to change its position */
  transform: translateY(-50%);
`;

export const UploadButton = styled.label`
  cursor: pointer;
  background: #100be5;
  color: white;
  font-size: 16px;
  padding: 6px 19px;
  border-radius: 7px;
  display: flex;
  margin-inline-end: 25px;
  margin-bottom: 0px !important;
  align-items: center;
  input {
    display: none;
  }
`;
