import axios from "axios";
import { BASE_URL } from "../Config/Config";

const authDataObject = {
  AdminSignUp: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/api/signup`,
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },

  AdminToken: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/api/signin`,
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },

  AdminUploadPresigned: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/api/documents`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },

  AdminUploadPresignedUrl: async (Url, file, metadata, callback) => {
    axios({
      method: "PUT",
      url: `${Url}`,
      headers: {
        "x-amz-meta-document_ext": metadata.document_ext,
        "x-amz-meta-document_id": metadata.document_id,
        "x-amz-meta-document_type": metadata.document_type,
        "x-amz-meta-user_id": metadata.user_id,
        "Content-Type": metadata?.contentType
          ? metadata.contentType
          : "text/plain",
      },
      data: file,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },

  AdminFileList: async () => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/documents`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  Adminpointdata: async (id, anotationtype) => {
    return await axios({
      method: "GET",
      url: `${BASE_URL}/api/annotations?document_id=${id}&annotation_type=${anotationtype}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  Admindownloadpdffile: async (id) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/documents/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  AdminGoogleLogindata: async (data, callback) => {
    axios({
      method: "Post",
      url: `${BASE_URL}/api/google-signin`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err.response);
      });
  },

  deleteDocument: async (data, callback) => {
    return await axios({
      method: "DELETE",
      url: `${BASE_URL}/api/documents?document_id=${data}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  getInsightPulse: async (document_id) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/annotation-progress?document_id=${document_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return result;
  },

  getAnnotationData: async (data) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/annotations?document_id=${data?.document_id}&annotation_type=${data?.annotation_type}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return result;
  },

  patchFileOpenStatus: async (data) => {
    return await axios({
      method: "PATCH",
      url: `${BASE_URL}/api/documents`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    });
  },

  patchFileName: async (data) => {
    return await axios({
      method: "PATCH",
      url: `${BASE_URL}/api/documents`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    });
  },

  userDetails: async (callback) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/user`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  getUsage: async (data) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/usage?from_date=${data.from_date}&to_date=${data.to_date}&fill_all=true&detailed=true`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  getCurrentUsage: async ({ detailed = false }) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/current_usage?detailed=${detailed}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  getInvoice: async () => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/receipts`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  getPaymentMethod: async () => {
    return await axios({
      method: "GET",
      url: `${BASE_URL}/api/payment-methods`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  getbilling: async () => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/billing`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  createUserDetails: async (data) => {
    const result = await axios({
      method: "Post",
      url: `${BASE_URL}/api/user`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    });
    return result;
  },

  createBilling: async (data) => {
    const result = await axios({
      method: "Put",
      url: `${BASE_URL}/api/billing`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    });
    return result;
  },

  addPaymentMethod: async (data) => {
    const result = await axios({
      method: "POST",
      url: `${BASE_URL}/api/payment-methods`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    });
    return result;
  },

  DeletePaymentMethod: async (id) => {
    const result = await axios({
      method: "DELETE",
      url: `${BASE_URL}/api/payment-methods?payment_method_id=${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  UpdatePaymentMethod: async (data) => {
    console.log(data, "-ddd");
    const result = await axios({
      method: "PATCH",
      url: `${BASE_URL}/api/payment-methods`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    });
    return result;
  },

  ForgotPassword: async (email) => {
    const result = await axios({
      method: "POST",
      url: `${BASE_URL}/api/reset-email`,
      data: {
        username: email,
      },
    });
    return result;
  },

  ResetPassword: async (data) => {
    const result = await axios({
      method: "POST",
      url: `${BASE_URL}/api/reset-password`,
      data: {
        vt: data.vt,
        password: data.password,
      },
    });
    return result;
  },

  UserValidate: async () => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/validate`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  VerifyEmail: async (token) => {
    console.log("tok", token);
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/verify?vt=${token}`,
    });
    return result;
  },

  UploadPasteLink: async (data) => {
    const result = await axios({
      method: "POST",
      url: `${BASE_URL}/api/document-url`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        url: data,
      },
    });
    return result;
  },

  getSubscriptionList: async () => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/subscription-list`,
    });
    return result;
  },

  getSubscriptions: async () => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/subscriptions`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  updateSubscription: async (data) => {
    const result = await axios({
      method: "POST",
      url: `${BASE_URL}/api/subscriptions`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        subscription_name: data,
      },
    });
    return result;
  },

  exportPDF: async ({ document_id, annotation_type, offset }) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/export?document_id=${document_id}&annotation_type=${annotation_type}&offset=${offset}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return result;
  },

  logout: async (token) => {
    const result = await axios({
      method: "GET",
      url: `${BASE_URL}/api/signout`,
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem("token")}`,
      },
    });
    return result;
  },
};
export default authDataObject;
