import React from "react";
import { Modal, ModalBody } from "reactstrap";

import "./deleteConfirmModal.css";

const DeleteConfirmModal = ({ isOpen, handleClose, handleConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      // size="md"
      centered
      backdrop
      scrollable
      fade
      className="modal-content-override"
    >
      <ModalBody className="container-modal">
        <h3 className="are-you-sure-text">Are you sure?</h3>
        <h6 className="warning-text">You won’t be able to revert this.</h6>
        <div className="action-button-container">
          <div>
            {" "}
            <button
              className="action-button"
              onClick={() => {
                handleConfirm();
                handleClose();
              }}
            >
              Yes delete
            </button>
          </div>
          <div>
            <button className="action-button" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteConfirmModal;
