import React from "react";
import "./UploadLinkButton.css";
import { Upload } from "lucide-react";

function UploadLinkButton({ sidebarStatus, onClick }) {
  return (
    <>
      <div className="container">
        <div className="container-flex" onClick={onClick}>
          <Upload size={16} color={"#9a9595"} strokeOpacity={1.5} />
          <span className={"button"}>Upload a File</span>
        </div>
      </div>
      <hr className={`${sidebarStatus ? "hr" : "hr-coll"}`} />
    </>
  );
}

export default UploadLinkButton;
