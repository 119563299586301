import React from "react";
import { Modal, ModalBody } from "reactstrap";

import "./errorAlert.css";
import { CircleX } from "lucide-react";

const ErrorAlert = ({ isOpen, handleClose, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      // size="md"
      centered
      backdrop
      scrollable
      fade
      className="modal-content-error-alert"
    >
      <ModalBody className="container-modal">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <CircleX
            style={{
              padding: 0,
              fontSize: "25px",
            }}
          />
          <span
            className="are-you-sure-text"
            style={{
              marginTop: 5,
            }}
          >
            Failed!
          </span>
        </div>
        <h6 className="warning-text">{message ?? "Something went wrong.!"}</h6>
        <div className="action-button-container">
          <div>
            <button
              className="action-button"
              onClick={() => {
                handleClose(false);
              }}
            >
              Dismiss
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ErrorAlert;
