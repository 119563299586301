import authDataObject from "../../../services/Auth_Controller/Auth_Controller";
import mastercard from "../../../assets/images/master.png";
import visacard from "../../../assets/images/visa.jpg";
import amexcard from "../../../assets/images/amexcard.jpg";
import defaultcard from "../../../assets/images/defaultcard.jpg";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import DeleteConfirmModal from "../../Commoncomponent/DeleteConfirmModal/DeleteConfirmModal";
import ConfirmationAlertModal from "./ConfirmationAlertModal";

const PaymentMethods = () => {
  const [filteredList, setFilteredList] = useState([]);
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [paymentUpdateAlert, setPaymentUpdateAlert] = useState({
    show: false,
    message: "",
  });

  const {
    data: paymentMethod,
    refetch,
    isFetching,
  } = useQuery("payment-methods", authDataObject.getPaymentMethod);

  useEffect(() => {
    if (paymentMethod) {
      setFilteredList(paymentMethod?.data?.details?.payment_methods);
    }
  }, [paymentMethod]);

  // stripe
  const stripe = useStripe();
  const elements = useElements();

  const handlePayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsCreating(true);
    // Use elements.getElement to get a reference to the CardElement.
    const cardElement = elements.getElement(CardElement);
    // Use stripe.createToken to create a token for the card.
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: document.querySelector(".name-on-card").value,
      },
    });

    if (error) {
      console.error(error);
      setPaymentUpdateAlert({
        show: true,
        message: error.message,
      });
      setIsCreating(false);
    } else {
      console.log("paymentMethod", paymentMethod);
      sendTokenToBackend(paymentMethod.id);
    }
  };

  const sendTokenToBackend = async (tokenId) => {
    console.log("Sending token to backend...", tokenId);

    try {
      const response = await authDataObject.addPaymentMethod({
        stripe_payment_method_id: tokenId,
        is_default: isDefault,
      });
      console.log("response", response);
      if (response?.status === 200) {
        refetch();
        setShowAddPaymentMethod(false);
        setIsCreating(false);
      } else {
        console.error("Error sending token to the backend.");
        setPaymentUpdateAlert({
          show: true,
          message: response.message,
        });
        setIsCreating(false);
      }
    } catch (error) {
      console.error("Error sending token to the backend:", error);
      setPaymentUpdateAlert({
        show: true,
        message: error.message,
      });
      setIsCreating(false);
    }
  };

  const [isDefault, setIsDefault] = useState(true);
  const handleCheckBox = (event) => {
    setIsDefault(event.target.checked);
  };

  const { mutate: deleteMutation, isLoading } = useMutation(
    authDataObject.DeletePaymentMethod,
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        setPaymentUpdateAlert({
          show: true,
          message: "Payment method delete failed",
        });
      },
    }
  );

  const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
    authDataObject.UpdatePaymentMethod,
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        setPaymentUpdateAlert({
          show: true,
          message: "Payment method update failed",
        });
      },
    }
  );

  return (
    <div>
      {isFetching || isLoading || isUpdating || isCreating ? (
        <div
          style={{
            height: "85%",
            width: "90%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: "1050",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <Spinner
            size={"xl"}
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />{" "}
          <span
            style={{
              color: "#6c757d",
            }}
          >
            Please wait...
          </span>
        </div>
      ) : null}

      {paymentUpdateAlert?.show && (
        <ConfirmationAlertModal
          isOpen={paymentUpdateAlert?.show}
          handleClose={() => {
            setPaymentUpdateAlert({
              show: false,
              message: "",
            });
          }}
          title={paymentUpdateAlert?.message}
        />
      )}
      <h1 className="subtitle">Payment methods</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "20px",
          marginBottom: "0",
          flexWrap: "wrap",
        }}
      >
        {filteredList?.map((cardInfo, index) => (
          <CardInfoItem
            key={index}
            cardInfo={cardInfo}
            onDelete={(id) => {
              deleteMutation(id);
            }}
            onUpdate={(id) => {
              updateMutation({
                default_payment_method_id: id,
              });
            }}
            cardCount={filteredList?.length}
          />
        ))}
      </div>
      {!showAddPaymentMethod && !isFetching && (
        <button
          className="add-payment-method-button"
          onClick={() => {
            setShowAddPaymentMethod(true);
          }}
        >
          Add payment method
        </button>
      )}
      {showAddPaymentMethod && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            marginTop: 10,
            width: "40%",
          }}
        >
          <div
            style={{
              marginTop: 5,
              fontWeight: "700",
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <hr />
            <span>Card information</span>
            <div className="card-element-container">
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      iconColor: "#15afe3",
                      width: "100%",
                      color: "rgb(10, 10, 8)",
                      fontWeight: "500",
                      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                      fontSize: "16px",
                      fontSmoothing: "antialiased",
                      ":-webkit-autofill": {
                        color: "#1a1917",
                      },
                      "::placeholder": {
                        color: "#c0c0c0",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 5,
              fontWeight: "700",
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <span>Name on card</span>
            <input
              type="text"
              className="name-on-card"
              placeholder="Name on card"
            />
          </div>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "0",
              cursor: "pointer",
              marginTop: 10,
            }}
          >
            <input
              type="checkbox"
              onChange={(event) => handleCheckBox(event)}
              checked={isDefault}
            />
            <div> Set as default payment method </div>
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "end",
              gap: "10px",
            }}
          >
            <button
              className="buttonstyle"
              onClick={(e) => handlePayment(e)}
              style={{
                outline: "none",
                border: "none",
              }}
              disabled={!stripe || (filteredList?.length === 0 && !isDefault)}
            >
              Add payment method
            </button>
            <button
              className="add-payment-method-button"
              onClick={() => {
                setShowAddPaymentMethod(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default PaymentMethods;

const CardInfoItem = ({ cardInfo, onDelete, onUpdate, cardCount }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <div className="borderpayment">
      <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
        <div style={{ padding: 5 }}>
          <img
            src={getCardImage(cardInfo?.brand)}
            alt={cardInfo?.brand}
            className="image"
          />

          {/* <FaCcMastercard className="image" fontSize={40} color="blue" /> */}
        </div>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            padding: "5px",
          }}
        >
          <span
            className="belowstyle"
            style={{
              color: "#615D5D",
            }}
          >{`••••${cardInfo?.last4}`}</span>
          <span className="belowstyle" style={{ marginTop: 10 }}>
            Expires {`${cardInfo?.exp_month}/${cardInfo?.exp_year}`}
          </span>
        </div>
      </div>
      {cardInfo?.is_default && (
        <>
          <span
            className="belowstyle"
            style={{ marginTop: 10, marginLeft: 10, fontSize: 17 }}
          >
            {cardInfo?.is_default && "Default"}
          </span>
          {cardCount === 1 && (
            <button
              // className="delete-payment-method-button"
              className="set-default-button"
              style={{
                marginTop: 10,
                marginLeft: 10,
                fontSize: 17,
                color: "red",
              }}
              onClick={() => {
                setIsDeleteOpen(true);
              }}
            >
              Delete
            </button>
          )}
        </>
      )}
      {!cardInfo?.is_default && (
        <div>
          <button
            className="set-default-button"
            style={{ marginTop: 10, marginLeft: 10, fontSize: 17 }}
            onClick={() => {
              onUpdate(cardInfo?.id);
            }}
          >
            Set Default
          </button>
          <button
            // className="delete-payment-method-button"
            className="set-default-button"
            style={{ marginTop: 10, marginLeft: 10, fontSize: 17 }}
            onClick={() => {
              setIsDeleteOpen(true);
            }}
          >
            Delete
          </button>
        </div>
      )}
      {isDeleteOpen && (
        <DeleteConfirmModal
          isOpen={isDeleteOpen}
          handleClose={() => setIsDeleteOpen(false)}
          handleConfirm={async () => {
            onDelete(cardInfo?.id);
          }}
        />
      )}
    </div>
  );
};

const getCardImage = (brand) => {
  switch (brand) {
    case "amex":
      return amexcard;
    case "visa":
      return visacard;
    case "mastercard":
      return mastercard;
    default:
      return defaultcard;
  }
};
