import React, { useState } from "react";
import DocuDivelog from "../../assets/images/DocDive_logo.jpg";
import { Link, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import authDataObject from "../../services/Auth_Controller/Auth_Controller";

const VerifyPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const vt = searchParams.get("vt");

  const [showConfirmation, setShowConfirmation] = useState({
    isReset: false,
    message: "Verifying your email...",
  });
  const { mutate, isLoading } = useMutation(
    () => authDataObject.VerifyEmail(vt),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          setShowConfirmation({
            isReset: true,
            message: "Email has been verified",
          });
        }
      },
      onError: () => {
        setShowConfirmation({
          isReset: true,
          message: "Email has been verified",
        });
      },
    }
  );
  useState(() => {
    mutate();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        border: "1px solid black",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          gap: "50px",
          width: "25%",
          height: "40%",
          padding: "20px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Link to="/">
          <img
            src={DocuDivelog}
            alt="docudivelogo"
            style={{
              width: "200px",
              height: "50px",
              cursor: "pointer",
              objectFit: "contain",
            }}
          />
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <>
            <span
              style={{
                fontSize: "20px",
                alignSelf: "center",
                margin: showConfirmation.isReset ? "0px 10%" : "0px 15%",
              }}
            >
              {showConfirmation.message ?? "Verifying your email..."}
            </span>
          </>
          <Link to="/">
            <span
              className={`forgot-pw-link ${isLoading ? "disabled" : ""}`}
              style={{ marginTop: 5 }}
            >
              Go back to login
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
