import React from "react";
import { Modal, ModalBody } from "reactstrap";

const VerificationEmailModal = ({ isOpen, handleClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      // size="md"
      centered
      backdrop
      scrollable
      fade
      className="modal-content-override"
    >
      <ModalBody className="container-modal">
        <h5
          className="are-you-sure-text"
          style={{
            marginBottom: 15,
            fontSize: 20,
          }}
        >
          Verification link sent to your email address.
        </h5>
        <h6 className="warning-text">
          Please check your email and verify to continue
        </h6>
        <div className="action-button-container">
          <div>
            {" "}
            <button
              className="action-button"
              onClick={() => {
                handleClose();
              }}
            >
              Okay
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VerificationEmailModal;
