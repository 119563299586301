// import libraries
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// import icons
import EllipseBlue from "../../../assets/images/Ellipse.png";
import EllipseLightBlue from "../../../assets/images/Ellipse_blue.png";
import DocDivelog from "../../../assets/images/DocDive_logo.jpg";
import { format } from "date-fns";

// import CSS file for styling
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  header: {
    flexDirection: "row",
    justifyContent: "start",
    marginBottom: 20,
  },
  logo: {
    width: 145,
    height: 25,
    marginBottom: 5,
  },
  title: {
    fontSize: 12,
    fontWeight: 900,
    color: "#000",
    marginBottom: 20,
  },
  pdfdetails: {
    fontSize: 10,
    color: "#9A9595",
    fontStyle: "normal",
    fontWeight: 900,
    marginBottom: 5,
  },
  question: {
    fontSize: 12,
    color: "#9A9595",
    fontStyle: "normal",
  },
  answer: {
    fontSize: 12,
    color: "#4F4B4B",
    fontStyle: "normal",
    marginLeft: 10,
  },
  bulletPoint: {
    width: 10,
    height: 10,
    marginRight: 10,
  },
  bulletDesign: {
    fontSize: 12,
    color: "#4F4B4B",
    marginRight: 5,
  },
  questionContainer: {
    display: "flex",
    marginBottom: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  lineSeparator: {
    border: "0.4px solid #9A9595",
    width: "98%",
    marginLeft: 2,
    marginRight: 2,
    marginTop: 10,
    marginBottom: 10,
  },
});

const MyDocument = ({ pdfname, chatmode, name }) => {
  const getDate = () => {
    return format(new Date(), "yyyy-MM-dd HH:mm:ss a");
  };

  const chatData = getChatData(chatmode);

  return (
    <>
      <Document>
        <Page style={styles.page}>
          <View style={styles.header}>
            <Image source={DocDivelog} style={styles.logo} />
          </View>
          <Text style={styles.pdfdetails}>Document name : {`${pdfname}`}</Text>
          {name == "Chat history" ? (
            <Text style={styles.pdfdetails}>Chat mode : {chatmode}</Text>
          ) : null}
          <Text style={styles.pdfdetails}>Export time : {getDate()}</Text>
          <View style={styles.lineSeparator} />
          <Text style={styles.title}>{name}</Text>
          {name == "Chat history" ? (
            <>
              {chatData?.map((item, index) => (
                <View key={index}>
                  <View>
                    <View style={styles.questionContainer}>
                      <Image source={EllipseBlue} style={styles.bulletPoint} />
                      <Text style={styles.question}>{item.request.sender}</Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 10,
                        marginLeft: 10,
                      }}
                    >
                      <Text style={styles.answer}>{item.request.message}</Text>
                    </View>
                  </View>
                  <View>
                    <View style={styles.questionContainer}>
                      <Image
                        source={EllipseLightBlue}
                        style={styles.bulletPoint}
                      />
                      <Text style={styles.question}>
                        {item.response.sender}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 10,
                        marginLeft: 10,
                      }}
                    >
                      <Text style={styles.answer}>{item.response.message}</Text>
                    </View>
                  </View>
                </View>
              ))}{" "}
            </>
          ) : null}
        </Page>
      </Document>
    </>
  );
};

const GeneratePdf = ({ pdfname, chatmode, name }) => {
  // import CSS file for styling
  const styles = StyleSheet.create({
    downloadLinkText: {
      textDecoration: "none",
      color: "#000000",
      fontFamily: "League Spartan",
      fontSize: 14,
      fontWeight: "500",
      letterSpacing: 0,
      textAlign: "left",
      margin: 4,
    },
  });

  return (
    <div>
      <PDFDownloadLink
        document={
          <MyDocument pdfname={pdfname} chatmode={chatmode} name={name} />
        }
        fileName={`${pdfname?.split(".")?.[0]}-${name.replace(/ /g, "_")}.pdf`}
      >
        {({ blob, url, loading, error }) => (
          <div style={styles.downloadLinkText}>{name}</div>
        )}
      </PDFDownloadLink>
    </div>
  );
};

const getChatData = (mode) => {
  switch (mode) {
    case "headings":
      return JSON.parse(localStorage.getItem("chat_with_docudive_headings"));
    case "questions":
      return JSON.parse(
        localStorage.getItem("chat_with_docudive_full_context")
      );
    case "blocks":
      return JSON.parse(
        localStorage.getItem("chat_with_docudive_blocks_and_text")
      );
    default:
      return null;
  }
};
export default GeneratePdf;
