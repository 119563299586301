import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import { preprocessLaTeX } from "./MarkdownRenderer";
import remarkGfm from "remark-gfm";

const MarkdownLiveRenderer = ({ markdown }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setText((prevText) => {
        const newText =
          prevText +
          preprocessLaTeX(markdown)?.substring(
            prevText.length,
            prevText.length + 10
          );
        if (newText.length >= markdown.length) {
          clearInterval(interval);
          return preprocessLaTeX(markdown);
        }
        return newText;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [markdown]);

  return (
    <Markdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
    >
      {text}
    </Markdown>
  );
};

export default MarkdownLiveRenderer;
