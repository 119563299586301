import React, { useState } from "react";
import PulseIcon from "../assets/images/icons8-pulse-50.png";
import "./insightPulse.css";
import { PulseModal } from "./PulseModal/PulseModal";
import { useEffect } from "react";
import { Collapse } from "reactstrap";
import { ChevronDown, ChevronUp } from "lucide-react";

const InsightPulse = ({
  selectedId,
  selectedFileName,
  annotations,
  annotationsBusiness,
  progress,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [displayName, setDisplayName] = useState("");

  const toggleAccordion = () => {
    setTimeout(() => {
      setIsOpen(!isOpen);
    }, 100);
  };

  useEffect(() => {
    // if (selectedId === null) {
    setIsOpen(false);
    // }
  }, [selectedId]);

  useEffect(() => {
    if (progress === 100) {
      setIsOpen(true);
    }
  }, [progress]);

  const [annotationsType, setAnnotationsType] = useState("");

  return (
    <div
      style={{
        display: "flex",
        width: "440px",
        borderRadius: "7px",
        position: "absolute",
        bottom: 7,
        zIndex: 200,
        overflow: "hidden",
        flexDirection: "column", // Change to column to align content bottom
        alignItems: "stretch", // Ensure children stretch to fit container width
      }}
      animate={{
        opacity: 1,
        scale: 1,
        maxHeight: "210px",
        minHeight: "70px",
      }}
    >
      <div className={`pulse-container`}>
        <div
          className={`pulse-flex`}
          style={{
            cursor: selectedId !== null ? "pointer" : "default",
          }}
          onClick={() => {
            selectedId !== null && toggleAccordion();
          }}
        >
          {progress < 100 && (
            <div
              className="progress-bar-pulse"
              style={{
                width: `${progress}%`,
                height: isOpen && "66px",
              }}
            ></div>
          )}
          <div className="pulse-flex-left">
            <h6
              className={
                isOpen || progress === 100 ? "pulse-title-open" : "pulse-title"
              }
            >
              Information Extractor
            </h6>
            <p className="pulse-subtitle">
              {progress === 100
                ? "discover your document's heartbeat"
                : progress === 0 && selectedId === null
                ? "select a document to view insights"
                : "processing your document's heartbeat..."}
            </p>
          </div>
          <div className="pulse-flex-right">
            <img src={PulseIcon} alt="pulse-icons" />
          </div>
          <span className="pulse-arrow">
            {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </span>
        </div>

        <Collapse isOpen={isOpen}>
          <div className="pulse-divider"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            {annotations?.length > 0 && (
              <div className="essential-container">
                <div className="headings-insight">Essential information</div>
                <div className={`pulse-flex-content`}>
                  <div className="pulse-flex-detail">
                    <ul className="pulse-flex-list">
                      {annotations?.map((item, index) => {
                        return index % 2 === 0 ? (
                          <li
                            className={`pulse-flex-list-item`}
                            key={index}
                            onClick={() => {
                              if (item.status === "completed") {
                                setIsModelOpen(true);
                                setAnnotationsType(item.annotation_type);
                                setDisplayName(
                                  item.display_name?.replace(/_/g, " ")
                                );
                              }
                            }}
                            style={{
                              pointerEvents:
                                item.status === "completed" ? "auto" : "none",
                              cursor:
                                item.status === "completed"
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                          >
                            {item.display_name?.replace(/_/g, " ")}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                  <div className="pulse-flex-detail">
                    <ul className="pulse-flex-list">
                      {annotations?.map((item, index) => {
                        return index % 2 !== 0 ? (
                          <li
                            className="pulse-flex-list-item"
                            key={index}
                            onClick={() => {
                              setIsModelOpen(true);
                              setAnnotationsType(item.annotation_type);

                              setDisplayName(
                                item.display_name?.replace(/_/g, " ")
                              );
                            }}
                            style={{
                              pointerEvents:
                                item.status === "completed" ? "auto" : "none",
                              cursor:
                                item.status === "completed"
                                  ? "pointer"
                                  : "default",
                            }}
                          >
                            {item.display_name?.replace(/_/g, " ")}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {annotationsBusiness?.length > 0 && (
              <div className="business-container">
                <div className="headings-insight">Business insights</div>
                <div className="pulse-flex-detail">
                  <ul className="pulse-flex-list">
                    {annotationsBusiness?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            setIsModelOpen(true);
                            setAnnotationsType(item.annotation_type);

                            setDisplayName(
                              item.display_name?.replace(/_/g, " ")
                            );
                          }}
                          className="pulse-flex-list-item"
                        >
                          {item.display_name?.replace(/_/g, " ")}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </Collapse>
      </div>

      {isModelOpen && (
        <PulseModal
          isOpen={isModelOpen}
          handleClose={(val) => setIsModelOpen(val)}
          annotationType={annotationsType}
          displayName={displayName}
          selectedId={selectedId}
          selectedFileName={selectedFileName}
        />
      )}
    </div>
  );
};

export default InsightPulse;
