// import libraries
import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

// import CSS file for styling
import "./pricingmodal.css";

// import components
import PricingComponent from "../../PaymentComponent/PricingComponent";
import { X } from "lucide-react";

const Pricingmodal = ({ isOpen, toggleModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      size="xl"
      centered
      className="custom-pricing-modal"
    >
      <ModalHeader
        toggle={toggleModal}
        className="custom-modal-header"
        style={{
          marginTop: 5,
        }}
      >
        {/* <FaTimes className="close-icon" onClick={toggleModal} /> */}
        <button
          className="close-button"
          style={{ position: "absolute", right: 20, top: 5 }}
          onClick={toggleModal}
        >
          <X
            className="close-button-icon"
            size={16}
            strokeWidth={4}
            color={"#808081"}
          />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="pricing-content">
          {" "}
          <PricingComponent />{" "}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Pricingmodal;
