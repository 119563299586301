// Import libraries
import React, { useState } from "react";

// Import CSS file for styling
import "./tab.css";

const TabsComponent = ({
  onSelectedTabChange,
  selectedId,
  isChatResponseLoading,
}) => {
  const [activeTab, setActiveTab] = useState("full_context");

  // useEffect(() => {
  //   onSelectedTabChange("full_context");
  //   setActiveTab("full_context");
  // }, [selectedId]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    onSelectedTabChange(tabId); // Call the function to pass the selected text
  };
  return (
    <div className="tab-flex">
      {/* <nav>
        <a
          className={activeTab === "full_context" ? "active" : "hover-class"}
          onClick={() => handleTabClick("full_context")}
        >
          Full context chat
        </a>
        <a
          className={activeTab === "explore_heading" ? "active" : "hover-class"}
          onClick={() => handleTabClick("explore_heading")}
        >
          Explore headings
        </a>
        <a
          className={
            activeTab === "analyse_your_pick" ? "active" : "hover-class"
          }
          onClick={() => handleTabClick("analyse_your_pick")}
        >
          Analyse your pick
        </a>
      </nav> */}
      <button
        className={`tab-button ${activeTab === "full_context" && "active"}`}
        onClick={() => handleTabClick("full_context")}
        disabled={!selectedId || isChatResponseLoading}
      >
        Full context chat
      </button>
      <button
        className={`tab-button ${activeTab === "explore_heading" && "active"}`}
        onClick={() => handleTabClick("explore_heading")}
        disabled={!selectedId || isChatResponseLoading}
      >
        Explore headings
      </button>
      <button
        className={`tab-button ${
          activeTab === "analyse_your_pick" && "active"
        }`}
        onClick={() => handleTabClick("analyse_your_pick")}
        disabled={!selectedId || isChatResponseLoading}
      >
        Analyze your pick
      </button>
      {/* <button
        className={`tab-button ${activeTab === "chat_with_ai" && "active"}`}
        onClick={() => handleTabClick("chat_with_ai")}
        // disabled
      >
        Chat with AI
      </button> */}
    </div>
  );
};

export default TabsComponent;
