import { useEffect, useRef, useState } from "react";
import authDataObject from "../../../services/Auth_Controller/Auth_Controller";
import { Spinner } from "reactstrap";
import ConfirmationAlertModal from "./ConfirmationAlertModal";
import { useMutation, useQuery, useQueryClient } from "react-query";

const ProfileDetails = () => {
  const [firstName, setFirstName] = useState(null);
  const [middleName, setMiddleName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNo, setphoneNo] = useState(null);
  const [profileIcon, setProfileIcon] = useState(
    localStorage.getItem("UserUploadedImage") || null
  );

  const [showSubmit, setShowSubmit] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);

  const { data: userData, isFetching } = useQuery(
    "user-details",
    authDataObject.userDetails
  );

  useEffect(() => {
    if (userData) {
      setFirstName(userData?.data?.details?.user_data?.given_name);
      setLastName(userData?.data.details?.user_data?.family_name);
      setMiddleName(userData?.data?.details?.user_data?.middle_name);
      setphoneNo(userData?.data?.details?.user_data?.mobile_no);
      setProfileIcon(userData?.data?.details?.user_data?.image_url);
      localStorage.setItem(
        "UserUploadedImage",
        userData?.data?.details?.user_data?.image_url
      );
    }
  }, [userData]);

  const editform = (key, value) => {
    setShowSubmit(true);
    if (key === "firstName") {
      setFirstName(value);
    } else if (key === "middleName") {
      setMiddleName(value);
    } else if (key === "lastName") {
      setLastName(value);
    } else if (key === "phoneNo") {
      setphoneNo(value);
    }
  };

  const queryClient = useQueryClient();
  const [profileUpdateAlert, setProfileUpdateAlert] = useState({
    show: false,
    message: "",
  });

  const submit = () => {
    // setLoading(true);
    setShowSubmit(false);
    const data = {
      given_name: firstName,
      middle_name: middleName,
      family_name: lastName,
      locale: "en",
      mobile_no: phoneNo,
      image_url: selectedIcon,
    };
    mutation.mutate(data);
  };

  const mutation = useMutation(authDataObject.createUserDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries("user-details");
      setProfileUpdateAlert({
        show: true,
        message: "Profile details updated successfully",
      });
      setSelectedIcon(null);
    },
    onError: () => {
      setProfileUpdateAlert({
        show: true,
        message: "Profile details updated failed",
      });
      setSelectedIcon(null);
    },
  });

  const fileInputRef = useRef(null);

  const handleEditProfileClick = () => {
    // Trigger the click event on the file input
    setShowSubmit(true);
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    // Handle the selected file here
    const selectedFile = event.target.files[0];
    convertToBase64(selectedFile);
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedIcon(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      {isFetching || mutation.isLoading ? (
        <div
          style={{
            height: "80%",
            width: "90%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: "1050",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <Spinner
            size={"xl"}
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />{" "}
          <span
            style={{
              color: "#6c757d",
            }}
          >
            Please wait...
          </span>
        </div>
      ) : null}
      <h1 className="subtitle">Details</h1>

      <div style={{ marginTop: 8 }}>
        <span className="belowstyle">
          Below you'll find details of your profile. You can edit your
          information and profile icon.
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <span className="noname">{localStorage.getItem("UserEmail")}</span>
        </div>{" "}
        <div>
          <span className="selfbutton">self</span>
        </div>
      </div>
      <div className="row" style={{ marginTop: "2rem" }}>
        <div
          style={{
            width: 59,
            height: 59,
            borderRadius: 50,
            backgroundColor: "#d9d9d9",
            marginLeft: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedIcon ? (
            <img
              src={selectedIcon}
              alt="profile icon"
              style={{
                borderRadius: 50,
                width: 59,
                height: 59,
              }}
            />
          ) : profileIcon ? (
            <img
              src={profileIcon}
              alt="profile icon"
              style={{
                borderRadius: 50,
                width: 59,
                height: 59,
              }}
            />
          ) : (
            <span
              style={{
                fontSize: 22,
                fontWeight: 700,
                color: "#000",
              }}
            >
              {localStorage.getItem("UserName")
                ? localStorage.getItem("UserName")
                : "UN"}
            </span>
          )}
        </div>
        <div className="editprofile">
          <span onClick={handleEditProfileClick}>Edit profile icon</span>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </div>
      </div>
      <div className="space inputdiv">
        {firstName ? <p className="inputhead">{"First Name"}</p> : []}
        <input
          value={firstName}
          className=" input "
          onChange={(e) => editform("firstName", e.target.value)}
          type="text"
        />
        {firstName ? [] : <p className="floating-label">{"First Name"}</p>}
      </div>
      <div className="space inputdiv">
        {middleName ? <p className="inputhead">{"Middle Name"}</p> : []}
        <input
          onChange={(e) => editform("middleName", e.target.value)}
          className=" input "
          value={middleName}
          type="text"
        />
        {middleName ? [] : <p className="floating-label">{"Middle Name"}</p>}
      </div>

      <div className="space inputdiv">
        {lastName ? <p className="inputhead">{"Last Name"}</p> : []}
        <input
          onChange={(e) => editform("lastName", e.target.value)}
          className=" input "
          value={lastName}
          type="text"
        />
        {lastName ? [] : <p className="floating-label">{"Last Name"}</p>}
      </div>

      <div className="contactdetail">
        <span>Contact Details</span>
      </div>

      <div className="space inputdiv">
        {phoneNo ? <p className="inputhead">{"Phone Number"}</p> : []}
        <input
          onChange={(e) => editform("phoneNo", e.target.value)}
          className=" input "
          value={phoneNo}
          type="text"
        />
        {phoneNo ? [] : <p className="floating-label">{"Phone Number"}</p>}
      </div>

      <div className="buttonContainer">
        <button
          disabled={!showSubmit}
          className="buttonstyle"
          onClick={() => submit()}
          type="button"
        >
          Save Changes
        </button>
      </div>

      {profileUpdateAlert?.show && (
        <ConfirmationAlertModal
          isOpen={profileUpdateAlert?.show}
          handleClose={() => {
            setProfileUpdateAlert({
              show: false,
              message: "",
            });
          }}
          title={profileUpdateAlert?.message}
        />
      )}
    </div>
  );
};

export default ProfileDetails;
