import { useEffect } from "react";
import Helmet from "react-helmet";

// import CSS file for styling
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import components
import Home from "./components/Home";
import ReactGA from "react-ga4";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ResetPassword from "./components/AuthComponent/ResetPassword";
import VerifyPage from "./components/AuthComponent/VerifyPage";

function App() {
  // Send a pageview event to Google Analytics when the component mounts
  useEffect(() => {
    ReactGA.initialize("G-RQDR53JGMY"); // Replace 'G-RQDR53JGMY' with your Google Analytics Measurement ID
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home page",
    });
  }, []);

  // Set meta tags using Helmet
  const metaTags = {
    title: "Docudive",
    description: "Docudive is an AI-powered PDF paraphrasing tool...",
    keywords:
      "pdf ai, ai pdf, pdf com, paraphrasing tool, chatgpt pdf, pdf to ai, documind, paraphrase tool, chat pdf ai",
    ogTitle: "Docudive: AI-Powered PDF Paraphrasing Tool",
    ogDescription: "Docudive is an AI-powered PDF paraphrasing tool...",
    ogImage:
      "https://dd-input-files.s3.amazonaws.com/ac2856b4-469c-4bd8-b8b6-101ca86e66c6/7922666a-efd6-41f4-ac9f-ba4c144f5add.png",
    ogUrl: "https://docudive.vercel.app/",
  };

  const stripePromise = loadStripe(
    "pk_test_51O21ZuEIx0QzrdrDLBFzYIFbhTejaCj4ibToyVsBoWK4p3XlrpUFiMjajn3qiaw8cNeFL0jjngMgVhx24ZtIeBCi00kP29Pcr6"
  );

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Helmet>
          {/* Set meta tags */}
          <title>{metaTags.title}</title>
          <meta name="description" content={metaTags.description} />
          <meta name="keywords" content={metaTags.keywords} />
          <meta property="og:title" content={metaTags.ogTitle} />
          <meta property="og:description" content={metaTags.ogDescription} />
          <meta property="og:image" content={metaTags.ogImage} />
          <meta property="og:url" content={metaTags.ogUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/verify" element={<VerifyPage />} />
        </Routes>
      </Router>
    </Elements>
  );
}

export default App;
