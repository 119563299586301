import React, { useMemo } from "react";
import Markdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import "katex/dist/katex.min.css";

export const preprocessLaTeX = (content) => {
  if (!content) {
    return content;
  }
  // Replace block-level LaTeX delimiters \[ \] with $$ $$

  const blockProcessedContent = content?.replace(
    /\\\[(.*?)\\\]/gs,
    (_, equation) => `$$${equation}$$`
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent?.replace(
    /\\\((.*?)\\\)/gs,
    (_, equation) => `$${equation}$`
  );
  return inlineProcessedContent;
};

const MarkdownRenderer = React.memo(({ markdown }) => {
  const processedMarkdown = useMemo(
    () => preprocessLaTeX(markdown),
    [markdown]
  );

  return (
    <Markdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
    >
      {processedMarkdown}
    </Markdown>
  );
});

export default MarkdownRenderer;
