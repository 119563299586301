// import libraries
import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

// import icons
import Google_icon from "../../assets/images/google.svg";

// import CSS file for styling
import "../../components/Commoncomponent/Modals/signinmodal.css";

// import controller object for api calling
import authDataObject from "../../services/Auth_Controller/Auth_Controller";

const GoogleLoginComponent = ({ onContinuegoogleClick, onStatusTrue }) => {
  const responseGoogle = (response) => {
    console.log(response.credential, "response");
    onStatusTrue(true);
    onContinuegoogleClick();
    handleGoogleLogin(response.credential);
    // Handle the Google login response here.
  };

  const handleGoogleLogin = (token) => {
    const body = {
      token: token,
    };
    // Perform some authentication operations
    authDataObject.AdminGoogleLogindata(body, (result) => {
      if (result?.data?.status === "success") {
        const icon = result?.data?.details?.icon;
        localStorage.setItem("UserUploadedImage", icon);
        localStorage.setItem("UserEmail", result?.data?.details?.username);
        localStorage.setItem("token", result?.data?.details?.access_token);
        localStorage.removeItem("UserName");
        onContinuegoogleClick();
        onStatusTrue(false);
      } else {
      }
    });
  };

  const customButton = (
    <button
      className="custom-google-button"
      onClick={(e) => {
        e.preventDefault(); // Event ko rokne ke liye preventDefault ka upayog karein
        GoogleLogin.performLogin();
      }}
    >
      <div className="google-icon-container">
        <img src={Google_icon} alt="google_icon" />
      </div>
      <div>Continue with Google</div>
    </button>
  );

  return (
    <div>
      <GoogleOAuthProvider clientId="739388002072-lqhmp6jbvhrvn7p40i3s27fmc41bsflh.apps.googleusercontent.com">
        <GoogleLogin
          render={(renderProps) => customButton}
          onSuccess={responseGoogle}
          onError={() => {
            console.log("Login Failed");
          }}
          shape="pill"
          width="238px"
          text="continue_with"
          logo_alignment="center"
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleLoginComponent;
