import React, { useRef, useState, useEffect, useMemo } from "react";
import { Collapse, Tooltip } from "reactstrap";
import "./chatcomponent.css";
import Unselect from "../assets/images/Unselect_icon.png";
import Select from "../assets/images/Select_icon.png";
import { PulseLoader } from "react-spinners";
import ChatDataObject from "../services/Auth_Controller/Chat_Controller";
import ExportDropDown from "./ExportDropDown";
import { Spinner } from "reactstrap";
import { useQuery, useQueryClient } from "react-query";
import ErrorAlert from "../components/Commoncomponent/ErrorAlert/ErrorAlert";
import MarkdownRenderer from "../components/Commoncomponent/MarkdownRenderer";
import MarkdownLiveRenderer from "../components/Commoncomponent/MarkdownLiveRenderer";
import { Circle, CircleArrowUp, CircleStop, RefreshCw } from "lucide-react";

const ChatComponent = ({
  headingsSaved,
  questionsSaved,
  selectedfilevalename,
  onKeyInfoClick,
  selectedId,
  selectedText,
  selectedTab,
  documenturl,
  annotations,
  annotationsBusiness,
  isQuestionsFetched,
  isHeadingsFetched,
  isBoundingBoxFetched,
  isLoaderActive,
  setIsLoaderActive,
}) => {
  const [currentImage, setCurrentImage] = useState(Select);
  const [arrowColor, setArrowColor] = useState("#d3d3d3");
  const [isActiveExplore_headings, setIsActiveExplore_headings] =
    useState(false);
  const [isActiveFull_context, setIsActiveFull_context] = useState(true);
  const [isActiveChatWithAi, setIsActiveChatWithAi] = useState(false);
  const [chattype, setChatType] = useState("questions");
  const [question_text, setQuestionText] = useState("");
  const [inputText, setInputText] = useState("");
  const [inputTextValue, setInputTextValue] = useState("");
  const [resetcontext, setResetcontext] = useState(true);
  const [chat_conversation, setChatConversation] = useState([]);
  const [qaPairs, setQaPairs] = useState([]);
  const [botResoponse, setBotResponse] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [disablestatus, setSelectedStatus] = useState(false);
  const [preheadings, setPreHeadings] = useState([]);

  const chatContainerRefNew = useRef(null);
  const [chatnamelist, setChatNameList] = useState([]);
  const [showStopButton, setShowStopButton] = useState(false);
  const [showRegenerateButton, setShowRegenerateButton] = useState(false);
  const [showError, setShowError] = useState({
    isOpen: false,
    message: "",
  });

  useEffect(() => {
    setQuestionText("");
    stopTypewriter();
    localStorage.setItem("isStopped", false);
    if (selectedTab === "full_context") {
      toggleActiveFull_context();
    } else if (selectedTab === "explore_heading") {
      toggleActiveExplore_headings();
    } else if (selectedTab === "analyse_your_pick") {
      setChatConversation(
        JSON.parse(localStorage.getItem("chat_with_docudive_blocks_and_text"))
      );
      setQaPairs([]);
    } else if (selectedTab === "chat_with_ai") {
      setIsActiveChatWithAi(true);
      setChatType("chatWithAI");
      setQaPairs([]);
      setIsActiveFull_context(false);
      setIsActiveExplore_headings(false);
      localStorage.removeItem("coordinates");
      // setSelectedQuestions([]);
      setResetcontext(true);
      localStorage.setItem("status", false);
      setCurrentImage(Select);
    }
  }, [selectedTab, selectedId]);

  useEffect(() => {
    let index = 1;
    let msg = setInterval(() => {
      if (index >= botResoponse.length) {
        clearInterval(msg);
      }
      index++;
    }, 5);
    return () => clearInterval(msg); // clear interval on component unmount
  }, [botResoponse]);

  //scroll chat bottom
  useEffect(() => {
    chatContainerRefNew?.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat_conversation, qaPairs, question_text, isLoaderActive]);

  useEffect(() => {
    if (selectedId) {
      localStorage.removeItem("chat_with_docudive_full_context");
      localStorage.removeItem("chat_with_docudive_headings");
      localStorage.removeItem("chat_with_docudive_blocks_and_text");
      localStorage.removeItem("chat_with_ai");
      localStorage.removeItem("chat_list");
      setChatConversation([]);
      setQaPairs([]);
      setQuestionText("");
      setInputText("");
      setInputTextValue("");
      setIsChatReady({
        ready: false,
        message: "",
      });
    } else {
      localStorage.removeItem("chat_with_docudive_full_context");
      localStorage.removeItem("chat_with_docudive_headings");
      localStorage.removeItem("chat_with_docudive_blocks_and_text");
      localStorage.removeItem("chat_with_ai");
      localStorage.removeItem("chat_list");
      setChatConversation([]);
      setQaPairs([]);
      setQuestionText("");
      setIsChatReady({
        ready: false,
        message: "",
      });
    }
  }, [selectedId]);

  useEffect(() => {
    setResetcontext(true);
  }, [selectedText, localStorage.getItem("coordinates")]);

  useEffect(() => {
    if (selectedTab == "analyse_your_pick") {
      localStorage.removeItem("coordinates");
      // setSelectedQuestions([]);
      setChatType("blocks");
      setResetcontext(true);
      onKeyInfoClick("blocks");
      setIsActiveFull_context(false);
      setIsActiveExplore_headings(false);
      setIsActiveChatWithAi(false);
    }
  }, [selectedTab]);

  const handleImageClick = () => {
    if (currentImage === Select) {
      setCurrentImage(Unselect);
    } else {
      setCurrentImage(Select);
    }
  };

  const toggleActiveExplore_headings = () => {
    setChatConversation(
      JSON.parse(localStorage.getItem("chat_with_docudive_headings"))
    );
    setQaPairs([]);
    localStorage.removeItem("coordinates");
    // setSelectedQuestions([]);
    onKeyInfoClick("headings");
    setChatType("headings");
    setResetcontext(true);
    localStorage.setItem("status", false);
    setCurrentImage(Select);
    setIsActiveExplore_headings(!isActiveExplore_headings);
    setIsActiveFull_context(false);
    setIsActiveChatWithAi(false);
  };

  const toggleActiveFull_context = () => {
    setChatConversation(
      JSON.parse(localStorage.getItem("chat_with_docudive_full_context"))
    );
    setQaPairs([]);
    localStorage.removeItem("coordinates");
    // setSelectedQuestions([]);
    localStorage.setItem("status", false);
    onKeyInfoClick("questions");
    setChatType("questions");
    setResetcontext(true);
    setCurrentImage(Select);
    setIsActiveFull_context(true);
    setIsActiveExplore_headings(false);
    setIsActiveChatWithAi(false);
  };

  const handleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      // Checkbox is checked, add the index to the selectedQuestions array
      setSelectedQuestions([...selectedQuestions, index]);
    } else {
      // Checkbox is unchecked, remove the index from the selectedQuestions array
      setSelectedQuestions(
        selectedQuestions.filter((selectedIndex) => selectedIndex !== index)
      );
    }
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    setInputTextValue(text);
    if (e.target.value.length > 0 && (documenturl || isActiveChatWithAi)) {
      setArrowColor("#100BE5");
    } else {
      setArrowColor("#d3d3d3");
    }
  };

  const [isChatReady, setIsChatReady] = useState({
    ready: false,
    message: "Please wait until the file is processed...",
  });

  // get chat data
  const { data: chatData, refetch } = useQuery({
    queryKey: [`chat-data-${selectedId}`, selectedId],
    queryFn: () => ChatDataObject.ChatList_data(selectedId),
    enabled: selectedId !== null && selectedId !== undefined,
  });
  useEffect(() => {
    const isReady =
      chatData?.data?.details?.chat_readiness === "ready" ||
      chatData?.data?.details?.chat_readiness === true;
    console.log("isReady", isReady);
    setIsChatReady({
      ready: isReady,
      message: chatData?.data?.details?.chat_status_message,
    });
    if (selectedId && !isReady) {
      localStorage.setItem("chat_list", JSON.stringify([]));
      // setChatConversation([]);
      const timeoutId = setTimeout(() => {
        refetch();
      }, 10000);

      return () => clearTimeout(timeoutId);
    }

    if (selectedId && chatData && isReady) {
      console.log("chatData", chatData);
      setIsChatReady({
        ready: isReady,
        message: "",
      });
      setChatNameList(chatData?.data?.details?.chat_list);
      localStorage.setItem(
        "chat_list",
        JSON.stringify(chatData?.data?.details?.chat_list)
      );
    }
  }, [selectedId, chatData]);

  const chatfullcontext = useMemo(() => {
    return chatData?.data?.details?.chat_list.find(
      (chat) => chat.chat_name === "chat_with_docudive_full_context"
    );
  }, [chatData]);

  useQuery({
    queryKey: [
      `chat-history-${selectedId}-${chatfullcontext?.chat_id}`,
      selectedId,
      chatfullcontext?.chat_id,
    ],
    queryFn: () =>
      ChatDataObject.ChatHistory_data(selectedId, chatfullcontext?.chat_id),
    enabled:
      selectedId !== null &&
      selectedId !== undefined &&
      chatfullcontext?.chat_id !== null &&
      chatfullcontext?.chat_id !== undefined,
    staleTime: 0,
    onSuccess: (result) => {
      if (chatfullcontext?.chat_name == "chat_with_docudive_full_context") {
        localStorage.setItem(
          "chat_with_docudive_full_context",
          JSON.stringify(result?.data?.details?.response?.conversation)
        );
        if (selectedTab === "full_context") {
          setChatConversation(result?.data?.details?.response?.conversation);
        }
      }
    },
  });

  const chatsummary = useMemo(() => {
    return chatData?.data?.details?.chat_list.find(
      (chat) => chat.chat_name === "chat_with_docudive_headings"
    );
  }, [chatData]);

  useQuery({
    queryKey: [
      `chat-history-${selectedId}-${chatsummary?.chat_id}`,
      selectedId,
      chatsummary?.chat_id,
    ],
    queryFn: () =>
      ChatDataObject.ChatHistory_data(selectedId, chatsummary?.chat_id),
    enabled:
      selectedId !== null &&
      selectedId !== undefined &&
      chatsummary?.chat_id !== null &&
      chatsummary?.chat_id !== undefined,
    staleTime: 0,
    onSuccess: (result) => {
      if (chatsummary?.chat_name == "chat_with_docudive_headings") {
        localStorage.setItem(
          "chat_with_docudive_headings",
          JSON.stringify(result?.data?.details?.response?.conversation)
        );

        if (selectedTab === "explore_heading") {
          setChatConversation(result?.data?.details?.response?.conversation);
        }
      }
    },
  });

  const chatblocks = useMemo(() => {
    return chatData?.data?.details?.chat_list.find(
      (chat) => chat.chat_name === "chat_with_docudive_blocks_and_text"
    );
  }, [chatData]);

  useQuery({
    queryKey: [
      `chat-history-${selectedId}-${chatblocks?.chat_id}`,
      selectedId,
      chatblocks?.chat_id,
    ],
    queryFn: () =>
      ChatDataObject.ChatHistory_data(selectedId, chatblocks?.chat_id),
    enabled:
      selectedId !== null &&
      selectedId !== undefined &&
      chatblocks?.chat_id !== null &&
      chatblocks?.chat_id !== undefined,
    staleTime: 0,
    onSuccess: (result) => {
      if (chatblocks?.chat_name == "chat_with_docudive_blocks_and_text") {
        localStorage.setItem(
          "chat_with_docudive_blocks_and_text",
          JSON.stringify(result?.data?.details?.response?.conversation)
        );
        if (selectedTab === "analyse_your_pick") {
          setChatConversation(result?.data?.details?.response?.conversation);
        }
      }
    },
  });

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  const queryClient = useQueryClient();
  const HandelChat_data = async (question_no, question_text, answer_text) => {
    localStorage.setItem("isStopped", false);
    setArrowColor("#d3d3d3");
    setCurrentImage(Unselect);
    if (question_no == undefined) {
      setShowStopButton(true);
    }
    setShowRegenerateButton(false);
    setIsLoaderActive(true);
    if (chattype == "questions" && question_text != undefined) {
      setIsLoaderActive(false);
      setSelectedStatus(false);
    } else {
      setSelectedStatus(true);
    }
    setInputTextValue("");
    if (question_text === undefined) {
      setQuestionText(inputText);
    }
    var contexttype,
      reset__context = false,
      chat_name;
    if (chattype == "text") {
      console.log("came to text");
      chat_name = "chat_with_docudive_blocks_and_text";
      contexttype = {
        context_type: "text",
        text: selectedText,
      };
    } else if (chattype == "blocks") {
      console.log("came to blocks");
      chat_name = "chat_with_docudive_blocks_and_text";
      var selectedcoordinates = localStorage.getItem("coordinates");
      var dynamicArray = [];
      if (selectedcoordinates) {
        const coordinatesObj = JSON.parse(selectedcoordinates);
        // Iterate through the keys of the coordinates object
        for (const key in coordinatesObj) {
          if (Object.hasOwnProperty.call(coordinatesObj, key)) {
            const subObject = coordinatesObj[key];
            // Iterate through the sub-keys of the current key
            for (const subKey in subObject) {
              if (Object.hasOwnProperty.call(subObject, subKey)) {
                // Increment the values by 1
                const incrementedKey = String(parseInt(key, 10));
                const incrementedSubKey = String(parseInt(subKey, 10) + 1);
                dynamicArray.push([incrementedKey, incrementedSubKey]);
              }
            }
          }
        }
      }
      contexttype = {
        context_type: "blocks",
        blocks: dynamicArray,
      };
    } else if (chattype == "headings") {
      chat_name = "chat_with_docudive_headings";
      var updatedHeadings = selectedQuestions.map((index) => index);
      if (!arraysAreEqual(updatedHeadings, preheadings)) {
        setPreHeadings(updatedHeadings);
        reset__context = true;
      }

      contexttype = {
        context_type: "headings",
        headings: updatedHeadings,
      };
    } else if (chattype == "questions" && question_text != undefined) {
      chat_name = "chat_with_docudive_full_context";
      contexttype = {
        context_type: "question",
        question: question_no,
      };
    } else if (chattype == "chatWithAI") {
      chat_name = "chat_with_ai";
      contexttype = {
        context_type: "text",
      };
    } else {
      chat_name = "chat_with_docudive_full_context";
      contexttype = {
        context_type: "full",
      };
    }
    var body,
      isemptytext = true;
    if (inputText != "" || question_text != undefined) {
      isemptytext = true;
    } else {
      isemptytext = false;
    }

    var resetcontextvalue = false;
    if (reset__context) {
      resetcontextvalue = true;
    } else {
      resetcontextvalue = resetcontext;
    }
    const chat = JSON.parse(localStorage.getItem("chat_list"))?.find(
      (chat) => chat.chat_name === chat_name
    );
    if (chat) {
      body = {
        document_id: !isActiveChatWithAi ? selectedId : "",
        chat_id: chat.chat_id,
        chat_name: chat_name,
        query: question_text ?? inputText,
        reset_context: inputText ? resetcontextvalue : true,
        context: contexttype,
      };
    } else {
      body = {
        document_id: !isActiveChatWithAi ? selectedId : "",
        chat_name: chat_name,
        query: question_text ?? inputText,
        reset_context: inputText ? resetcontextvalue : true,
        context: contexttype,
      };
    }
    if (isemptytext) {
      try {
        const result = await ChatDataObject.Chat_data(body);
        const isChatStopped = localStorage.getItem("isStopped");
        if (isChatStopped === "true") {
          setSelectedStatus(false);
          setResetcontext(false);
          setQuestionText("");
          setShowStopButton(false);
          setShowRegenerateButton(true);
          return;
        }

        if (result?.data?.status == "success") {
          setSelectedStatus(false);
          setResetcontext(false);
          setQuestionText("");
          queryClient.invalidateQueries([`current-usage-nav-bar`]);
          if (!chat) {
            // Get the chat list from local storage
            const chatListFromLocalStorage = JSON.parse(
              localStorage.getItem("chat_list")
            );

            // Add a new chat object to the chat list
            chatListFromLocalStorage.push({
              chat_id: result?.data?.details?.chat_id,
              chat_name: chat_name,
              created_at: result?.data?.details?.timestamp,
            });

            // Store the chat list in local storage
            localStorage.setItem(
              "chat_list",
              JSON.stringify(chatListFromLocalStorage)
            );
          }
          if (chat_name == "chat_with_docudive_full_context") {
            // Get the chat history from local storage
            const chathistoryFromLocalStorage = JSON.parse(
              localStorage.getItem("chat_with_docudive_full_context")
            );

            // Check if the local storage is empty
            if (
              chathistoryFromLocalStorage === null ||
              chathistoryFromLocalStorage.length === 0
            ) {
              // If the local storage is empty, push the first object
              var chathistoryLocalStorage = [];
              chathistoryLocalStorage.push({
                request: {
                  sender: "ME",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined ? inputText : question_text,
                },
                response: {
                  sender: "DOCUDIVE",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined
                      ? result?.data?.details?.response
                      : answer_text,
                },
                usage: {
                  tokens: 55,
                  model: "gpt-3.5-turbo",
                },
              });
              // Store the chat list in local storage
              localStorage.setItem(
                "chat_with_docudive_full_context",
                JSON.stringify(chathistoryLocalStorage)
              );
            } else {
              // If the local storage is not empty, push the new object
              chathistoryFromLocalStorage.push({
                request: {
                  sender: "ME",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined ? inputText : question_text,
                },
                response: {
                  sender: "DOCUDIVE",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined
                      ? result?.data?.details?.response
                      : answer_text,
                },
                usage: {
                  tokens: 55,
                  model: "gpt-3.5-turbo",
                },
              });
              // Store the chat list in local storage
              localStorage.setItem(
                "chat_with_docudive_full_context",
                JSON.stringify(chathistoryFromLocalStorage)
              );
            }
          } else if (chat_name == "chat_with_docudive_blocks_and_text") {
            // Get the chat history from local storage
            const chathistoryFromLocalStorage = JSON.parse(
              localStorage.getItem("chat_with_docudive_blocks_and_text")
            );

            if (
              chathistoryFromLocalStorage === null ||
              chathistoryFromLocalStorage.length === 0
            ) {
              // If the local storage is empty, push the first object
              var chathistoryLocalStorage = [];
              chathistoryLocalStorage.push({
                request: {
                  sender: "ME",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined ? inputText : question_text,
                },
                response: {
                  sender: "DOCUDIVE",
                  timestamp: result?.data?.details?.timestamp,
                  message: result?.data?.details?.response,
                },
                usage: {
                  tokens: 55,
                  model: "gpt-3.5-turbo",
                },
              });
              // Store the chat list in local storage
              localStorage.setItem(
                "chat_with_docudive_blocks_and_text",
                JSON.stringify(chathistoryLocalStorage)
              );
            } else {
              // If the local storage is not empty, push the new object
              chathistoryFromLocalStorage.push({
                request: {
                  sender: "ME",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined ? inputText : question_text,
                },
                response: {
                  sender: "DOCUDIVE",
                  timestamp: result?.data?.details?.timestamp,
                  message: result?.data?.details?.response,
                },
                usage: {
                  tokens: 55,
                  model: "gpt-3.5-turbo",
                },
              });
              // Store the chat list in local storage
              localStorage.setItem(
                "chat_with_docudive_blocks_and_text",
                JSON.stringify(chathistoryFromLocalStorage)
              );
            }
          } else if (chat_name == "chat_with_docudive_headings") {
            // Get the chat history from local storage
            const chathistoryFromLocalStorage = JSON.parse(
              localStorage.getItem("chat_with_docudive_headings")
            );

            if (
              chathistoryFromLocalStorage === null ||
              chathistoryFromLocalStorage.length === 0
            ) {
              // If the local storage is empty, push the first object
              var chathistoryLocalStorage = [];
              chathistoryLocalStorage.push({
                request: {
                  sender: "ME",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined ? inputText : question_text,
                },
                response: {
                  sender: "DOCUDIVE",
                  timestamp: result?.data?.details?.timestamp,
                  message: result?.data?.details?.response,
                },
                usage: {
                  tokens: 55,
                  model: "gpt-3.5-turbo",
                },
              });
              // Store the chat list in local storage
              localStorage.setItem(
                "chat_with_docudive_headings",
                JSON.stringify(chathistoryLocalStorage)
              );
            } else {
              // If the local storage is not empty, push the new object
              chathistoryFromLocalStorage.push({
                request: {
                  sender: "ME",
                  timestamp: result?.data?.details?.timestamp,
                  message:
                    question_text == undefined ? inputText : question_text,
                },
                response: {
                  sender: "DOCUDIVE",
                  timestamp: result?.data?.details?.timestamp,
                  message: result?.data?.details?.response,
                },
                usage: {
                  tokens: 55,
                  model: "gpt-3.5-turbo",
                },
              });
              // Store the chat list in local storage
              localStorage.setItem(
                "chat_with_docudive_headings",
                JSON.stringify(chathistoryFromLocalStorage)
              );
            }
          }

          if (chattype == "questions" && question_text != undefined) {
          } else {
            setIsLoaderActive(false);
            setQaPairs([
              ...qaPairs,
              {
                question: inputText,
                answer: result?.data?.details?.response,
              },
            ]);
            setShowStopButton(false);
            setShowRegenerateButton(true);
          }
        } else {
          setShowStopButton(false);
          setIsLoaderActive(false);
          setSelectedStatus(false);
          setResetcontext(false);
          setInputText("");
          setQuestionText("");
          setShowError({
            isOpen: true,
            message: result.data.message ?? "Something went wrong",
          });
        }
      } catch (error) {
        setShowStopButton(false);
        setIsLoaderActive(false);
        setSelectedStatus(false);
        setResetcontext(false);
        setQuestionText("");
        setInputText("");
        setShowError({
          isOpen: true,
          message: error.response.data.message ?? "Something went wrong",
        });
      }
    }
  };

  const stopTypewriter = () => {
    if (isLoaderActive) {
      setIsLoaderActive(false);

      setQaPairs([...qaPairs, { question: inputText, answer: "" }]);
    }
  };

  const startTypeWriter = () => {
    if (inputText.length > 0) {
      localStorage.setItem("isStopped", false);
      setIsLoaderActive(true);
      HandelChat_data();
    }
  };

  return (
    <>
      {selectedId !== null && !isChatReady.ready && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "101",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
            width: "100%",
            height: "calc(100%)",
          }}
        >
          <Spinner
            size={"xl"}
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />
          <span
            style={{
              color: "#6c757d",
            }}
          >
            {isChatReady.message ??
              "Please wait until the file is processed..."}
          </span>
        </div>
      )}
      <div className="dropdown-tabcontainer">
        <div
          style={{
            marginRight: "auto",
          }}
        >
          {!isActiveExplore_headings &&
          !isActiveFull_context &&
          !isActiveChatWithAi ? (
            <div className="chatbar-heading">
              Your chat is now personalized according to the content you've
              chosen.
            </div>
          ) : null}
          {isActiveChatWithAi && null}
          {isActiveFull_context ? (
            <div className="chatbar-heading">
              Your conversation encompasses the entire document context.
            </div>
          ) : null}
          {isActiveExplore_headings ? (
            <div className="chatbar-heading">
              Your conversation is now focused around the headings you've
              selected
            </div>
          ) : null}
        </div>
        {/* {switchshow ? (
          <SwitchButton
            selectedfilevalename={selectedfilevalename}
            onDropdownItemChange={handleDropdownChange}
            onSelectedItemChange={onSelectedItemChange}
          />
        ) : (
          ""
        )} */}

        <ExportDropDown
          selectedId={selectedId}
          isEnable={isChatReady.ready}
          // chat_conversation={chat_conversation}
          selectedFileName={selectedfilevalename?.file_name}
          chattype={chattype}
          annotations={annotations}
          annotationsBusiness={annotationsBusiness}
        />
      </div>

      {!selectedId && !isActiveChatWithAi ? (
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1",
          }}
        >
          <span className="chatbar-heading">
            Please select a document to start the conversation
          </span>
        </div>
      ) : null}

      {selectedTab === "analyse_your_pick" &&
        selectedId !== null &&
        isChatReady.ready &&
        !isBoundingBoxFetched && (
          <div
            style={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "101",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Spinner
              size={"xl"}
              className="m-1 spinner-loader"
              color="secondary"
              style={{
                width: "50px",
                height: "50px",
                zIndex: "102",
              }}
            />
            <span
              style={{
                color: "#6c757d",
              }}
            >
              Please wait while we fetch the bounding boxes...
            </span>
          </div>
        )}

      <div className="chat-section-main-container">
        <div className={"chat-section-sub-main-container"}>
          <div className="chat-main-text-container">
            {chat_conversation?.map((item, index) => (
              <div key={index}>
                <div className="chat-container">
                  <div>
                    <Circle fill="#09b1f3" color="#09b1f3" size={16} />
                  </div>
                  <div className="chatsub-container">
                    <div className="chat-heading">{item.request.sender}</div>
                    <div className="chatsubheading">{item.request.message}</div>
                  </div>
                </div>
                <div className="chat-container">
                  <div>
                    <Circle fill="#100be5" color="#100be5" size={16} />
                  </div>
                  <div className="chatsub-container">
                    <div className="chat-heading">{item.response.sender}</div>
                    <div className="chatsubheading">
                      {/* <pre
                      className="chatsubheading"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {item.response.message}
                    </pre> */}

                      <MarkdownRenderer markdown={item.response.message} />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div ref={chatContainerRefNew}></div>
            {qaPairs &&
              qaPairs.length > 0 &&
              qaPairs.map((qaPair, i) => (
                // <li key={qaPair.question}>
                <React.Fragment key={i}>
                  <div className="chat-container">
                    <div>
                      <Circle fill="#09b1f3" color="#09b1f3" size={16} />
                    </div>
                    <div className="chatsub-container">
                      <div className="chat-heading">ME</div>
                      <div className="chatsubheading">{qaPair.question}</div>
                    </div>
                  </div>
                  <div className="chat-container">
                    <div>
                      <Circle fill="#100be5" color="#100be5" size={16} />
                    </div>
                    <div className="chatsub-container">
                      <div className="chat-heading">DOCUDIVE</div>
                      <div className="chatsubheading">
                        {/* <Typewriter
                          options={{
                            delay: 0,
                            speed: 1,
                          }}
                          onInit={(typewriter) => {
                            typewriterRef.current = typewriter;
                            setBotResponse(qaPair?.answer);
                            typewriter
                              .typeString(`${qaPair.answer}`)
                              .start()
                              .callFunction(() => {
                                setShowStopButton(false);
                                setShowRegenerateButton(true);
                              });
                          }}
                        /> */}
                        {/* <Typed
                          strings={[qaPair?.answer]}
                          typeSpeed={0}
                          typedRef={(typed) => {
                            typedRef.current = typed;
                          }}
                          showCursor={false}
                          onComplete={() => {
                            setShowStopButton(false);
                            setShowRegenerateButton(true);
                          }}
                        /> */}
                        <MarkdownLiveRenderer markdown={qaPair?.answer} />
                        {/* <TypingEffect text={qaPair?.answer} speed={1} /> */}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                // </li>
              ))}

            {question_text && isLoaderActive ? (
              <>
                {" "}
                <div className="chat-container">
                  <div>
                    <Circle fill="#09b1f3" color="#09b1f3" size={16} />
                  </div>
                  <div className="chatsub-container">
                    <div className="chat-heading">ME</div>
                    <div className="chatsubheading">{question_text}</div>
                  </div>
                </div>
                <div className="chat-container">
                  <div>
                    <Circle fill="#100be5" color="#100be5" size={16} />
                  </div>
                  <div className="chatsub-container">
                    <div className="chat-heading">DOCUDIVE</div>
                    <div className="chatsubheading">
                      <div className="loader-container">
                        <PulseLoader color="#9A9595" size={6} loading={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <Collapse
            isOpen={
              selectedId !== null &&
              isActiveExplore_headings &&
              currentImage == Select &&
              documenturl &&
              headingsSaved?.length > 0
            }
          >
            <div className="heading-container">
              <span
                style={{
                  color: "#9A9595",
                  fontSize: "14px",
                  fontWeight: "400",
                  padding: "0px 10px",
                }}
              >
                Select headings. Now the answer will be confined to the selected
                headings.
              </span>
              <div
                style={{
                  overflowY: "scroll",
                  height: "140px",
                  padding: "0px 10px",
                }}
                className="heading-scrollbar"
              >
                <div className="pdfquestions-container">
                  {headingsSaved?.length > 0 ? (
                    <div className="questions-container">
                      {headingsSaved?.map((headingsObj, index) => {
                        const headingsKey = Object.keys(headingsObj)[0]; // Get the question key
                        const headings = headingsObj[headingsKey]; // Get the question string
                        return (
                          <div key={index} className="pdfquestions">
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                marginBottom: "0",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="checkbox"
                                onChange={(event) =>
                                  handleCheckboxChange(event, index)
                                }
                                checked={selectedQuestions.includes(index)}
                              />
                              <div className="pdfquestion">{headings} </div>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Collapse>

          <Collapse
            isOpen={
              selectedId !== null &&
              isActiveFull_context &&
              currentImage === Select &&
              documenturl &&
              questionsSaved?.length > 0
            }
          >
            <div className="pdf-questions-main-full-text-container">
              <div className="pdfquestions-container">
                {questionsSaved && Array.isArray(questionsSaved) ? (
                  <div className="questions-container">
                    {questionsSaved.map((questionObj, index) => {
                      const questionKey = Object.keys(questionObj)[0]; // Get the question key
                      const question = questionObj[questionKey]; // Get the question string
                      const answerKey = Object.keys(questionObj)[1]; // Get the question key
                      const answer = questionObj[answerKey]; // Get the question string

                      return (
                        <div key={index} className="pdfquestions">
                          <div
                            className="pdfquestion"
                            onClick={() => {
                              // setShowStopButton(true);
                              // setInputText(question);
                              HandelChat_data(index, question, answer);
                              setQaPairs([
                                ...qaPairs,
                                { question: question, answer: answer },
                              ]);
                              handleImageClick();
                            }}
                          >
                            {question}{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Collapse>
          <span
            style={{
              fontSize: "12px",
              marginLeft: "2px",
              color: "#9a9595",
            }}
          >
            Chat can sometimes generate incorrect information
          </span>
          <div className="chatbar-maincontainer">
            <input
              disabled={
                !isChatReady.ready ||
                (isActiveExplore_headings && selectedQuestions.length === 0)
              }
              className="chatbar-container"
              type="text"
              placeholder={
                isActiveExplore_headings && selectedQuestions.length === 0
                  ? "Select headings before asking a question."
                  : "Ask the document a question."
              }
              value={inputTextValue}
              onChange={(e) => {
                e.stopPropagation();
                handleInputChange(e);
              }} // Your event handler function
              onClick={(e) => {
                e.stopPropagation();
                // setCurrentsearchabrImage(Searchbarcolor);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (isChatReady.ready) {
                  if (e.key === "Enter") {
                    // Enter key is pressed
                    if (!disablestatus || isActiveChatWithAi) {
                      HandelChat_data(); // Call your function here
                      // scrollToBottom();
                    }
                  }
                }
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button
                style={{
                  outline: "none",
                  border: "none",
                }}
                disabled={!isChatReady.ready}
                onClick={() => handleImageClick()}
              >
                {selectedTab === "full_context" ||
                selectedTab === "explore_heading" ? (
                  <>
                    {selectedId !== null &&
                    ((selectedTab === "full_context" && !isQuestionsFetched) ||
                      (selectedTab === "explore_heading" &&
                        !isHeadingsFetched)) ? (
                      <Spinner
                        size={"sm"}
                        color="secondary"
                        className="spinner-loader"
                      />
                    ) : (
                      <img
                        src={currentImage}
                        style={{ height: "13px", width: "26px" }}
                        alt="Displayed"
                      />
                    )}
                  </>
                ) : null}
              </button>
              {showStopButton && inputText.length > 0 ? (
                <button
                  style={{
                    outline: "none",
                    border: "none",
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    setShowStopButton(false);
                    setShowRegenerateButton(true);
                    stopTypewriter();
                    localStorage.setItem("isStopped", true);
                  }}
                >
                  <CircleStop
                    size={24}
                    color={"#f5f0f0"}
                    fill={"#100be5"}
                    strokeWidth={2}
                  />
                </button>
              ) : (
                <button
                  style={{
                    outline: "none",
                    border: "none",
                    height: "24px",
                    width: "24px",
                  }}
                  disabled={
                    !isChatReady.ready ||
                    showStopButton ||
                    inputTextValue.length === 0
                  }
                  onClick={() => HandelChat_data()}
                >
                  <CircleArrowUp
                    size={24}
                    color={"#f5f0f0"}
                    fill={arrowColor}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
        {showError && (
          <ErrorAlert
            isOpen={showError.isOpen}
            handleClose={() => setShowError(false)}
            message={showError.message}
          />
        )}
        <div>
          {showRegenerateButton && inputText.length > 0 && (
            <ReGeneratingButton
              onClick={() => {
                setShowRegenerateButton(false);
                setShowStopButton(true);
                startTypeWriter();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChatComponent;

const ReGeneratingButton = ({ onClick }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <button
        id="regenearate"
        style={{
          outline: "none",
          border: "none",
          height: "18px",
          width: "18px",
          position: "absolute",
          right: "22px",
          backgroundColor: "transparent",
          marginTop: "10px",
        }}
        onClick={onClick}
      >
        <RefreshCw size={18} color="#9A9595" />
      </button>
      <Tooltip
        isOpen={tooltipOpen}
        target="regenearate"
        toggle={toggle}
        placement="bottom"
        style={{
          backgroundColor: "transparent",
          color: "#9A9595",
          fontSize: 12,
          width: 120,
        }}
      >
        Regenearate
      </Tooltip>
    </>
  );
};
