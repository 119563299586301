import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner, Tooltip } from "reactstrap";
import "./pulseModal.css";
import ExoprtIcon from "../../assets/images/export_file_icon.svg";
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import { useQuery } from "react-query";
import MarkdownRenderer from "../../components/Commoncomponent/MarkdownRenderer";
import { Copy, X } from "lucide-react";

export const PulseModal = ({
  isOpen,
  handleClose,
  annotationType,
  displayName,
  selectedId,
  selectedFileName,
}) => {
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const [showError, setShowError] = useState(false);

  const { data: annotationData, isFetching } = useQuery({
    queryKey: [
      `annotation-${selectedId}-${annotationType}`,
      selectedId,
      annotationType,
    ],
    queryFn: () =>
      authDataObject.getAnnotationData({
        document_id: selectedId,
        annotation_type: annotationType,
      }),
    enabled: annotationType !== null && selectedId !== null,
  });

  useEffect(() => {
    if (annotationData) {
      setDataList(filterData(annotationData?.data));
    }
  }, [annotationData]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(dataList, null, 2));
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1000);
  };

  const timeZoneOffset = useMemo(() => {
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;
    const sign = offset > 0 ? "-" : "+";
    const formattedOffset = `${sign}${offsetHours}${
      offsetMinutes > 0
        ? "." + (offsetMinutes / 60).toFixed(1).substring(2)
        : ""
    }`;

    return formattedOffset;
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      size="xl"
      centered
      backdrop
      scrollable
      fade
    >
      <ModalHeader
        className="modal-header-custom"
        toggle={() => handleClose(false)}
      >
        <div className="modal-header-container">
          <div className="title-text">{displayName}</div>
          <div className="vert-line"></div>
          <div className="sub-title-text">
            {getSubTitleText(annotationType)}
          </div>
          <div className="action-container">
            <div className="vert-line"></div>
            <div className="button-container">
              <Copy
                id="copy-icon"
                size={20}
                color="#8d8a8a"
                cursor={"pointer"}
                className="copy-icon-pulse-modal"
                onClick={() => {
                  handleCopy();
                }}
                style={{
                  marginRight: "5px",
                }}
              />

              <button
                className="icon-text-container"
                onClick={async () => {
                  setIsLoading(true);
                  const res = await authDataObject.exportPDF({
                    document_id: selectedId,
                    annotation_type: annotationType,
                    offset: timeZoneOffset ? timeZoneOffset : "+00.00",
                  });
                  if (res.status === 200) {
                    setIsLoading(false);
                    let url = res.data?.details?.presigned_url;
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${selectedFileName
                      ?.split(".")
                      .slice(0, -1)
                      .join(".")}-${displayName.replace(/ /g, "_")}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                }}
              >
                <span>
                  {isFetching || isLoading ? (
                    <Spinner
                      color="secondary"
                      size="sm"
                      style={{
                        borderWidth: "2px",
                      }}
                    />
                  ) : (
                    <img src={ExoprtIcon} alt="export icon" />
                  )}
                </span>
                <span className="text">Export</span>
              </button>

              <button
                className="close-button-pulse-modal"
                onClick={() => handleClose(false)}
              >
                <X
                  className="close-button-icon"
                  size={16}
                  strokeWidth={4}
                  color={"#808081"}
                />
              </button>
            </div>
          </div>
        </div>
        <Tooltip
          isOpen={tooltipOpen}
          target="copy-icon"
          style={{
            backgroundColor: "#9c9b9b",
            color: "white",
            maxWidth: "1500px",
          }}
        >
          Copied!
        </Tooltip>
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: "calc(100vh - 150px)",
          overflowY: "auto",
          marginRight: "2px",
        }}
      >
        <div
          className="modal-body-container"
          style={{
            justifyContent: isFetching ? "center" : "flex-start",
          }}
        >
          {isFetching ? (
            <Spinner
              color="secondary"
              size={60}
              style={{
                borderWidth: "2px",
              }}
            />
          ) : (
            <div
              className={`${
                annotationType === "ner" || annotationType === "info_snippets"
                  ? "ner-grid"
                  : "modal-body-ul"
              }`}
            >
              {!isFetching && (
                <RenderList
                  key={annotationType}
                  data={dataList}
                  type={annotationType}
                />
              )}
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

const getSubTitleText = (type) => {
  switch (type) {
    case "timeline":
      return "Following are the extracted timeline from the document";
    case "summary":
      return "Following is the extracted summary from the document";
    case "headings":
      return "Following are the extracted headings and their corresponding summaries";
    case "ner":
      return "Following are the extracted Named Entities from the document";
    case "citations":
      return "Following are the extracted citations from the document";
    case "cited_examples":
      return "Following are the extracted examples cited in the document";
    case "topics":
      return "Following are the extracted topics and their corresponding summary";
    case "info_snippets":
      return "Following are the extracted information from the document";
    case "financial_info":
      return "Following are the extracted financial information from the document";
    case "educational_info":
      return "Following are the extracted educational information";
    case "editorial_info":
      return "Following are the extracted editorial information from the document";
    case "legal_info":
      return "Following are the extracted legal information from the document";
    default:
      return null;
  }
};

const filterData = (data) => {
  switch (data?.details?.annotation_type) {
    case "timeline":
      return data.details.response;
    case "summary":
      return data.details.response;
    case "headings":
      return data.details.response;
    case "ner":
      return data?.details?.response;
    case "citations":
      return data?.details?.response;
    case "cited_examples":
      return data?.details?.response;
    case "topics":
      return data?.details?.response;
    case "info_snippets":
      return data?.details?.response;
    case "financial_info":
      return data?.details?.response;
    case "educational_info":
      return data?.details?.response;
    case "editorial_info":
      return data?.details?.response;
    case "legal_info":
      return data?.details?.response;
    default:
      console.log("default", data);
  }
};

const RenderList = ({ data, type }) => {
  if (type === "ner") {
    return getNERDataList(data?.data);
  } else if (type === "info_snippets") {
    return getInfoSnippets(data?.data);
  }

  if (data?.response_type === "JSON") {
    return <InsightWithTableOfContent data={data?.data} />;
  } else if (data?.response_type === "Markdown") {
    return (
      <div>
        <MarkdownRenderer markdown={data?.data} />
      </div>
    );
  }
};

//JSON with Table of Content
const InsightWithTableOfContent = ({ data }) => {
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "75%",
        }}
      >
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <span className="heading-h" id={index}>
              {item?.heading}{" "}
            </span>

            <div className="heading-s">
              <MarkdownRenderer markdown={item?.content} />
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          width: "20%",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
          padding: "15px",
          height: "fit-content",
          position: "sticky",
          top: "10px",
          right: "10px",
        }}
      >
        <h6 style={{ padding: "5px", color: "#928FF4" }}>Table of contents</h6>
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            {" "}
            <Tooltip
              placement="left"
              isOpen={tooltipOpen[index] || false}
              target={`table-of-content-item-${index}`}
              toggle={() => toggleTooltip(index)}
              style={{
                backgroundColor: "#9c9b9b",
                color: "white",
                alignSelf: "flex-start",
                // maxWidth: "1500px",
              }}
            >
              {item?.heading}
            </Tooltip>
            <span
              className="table-of-content-item"
              id={`table-of-content-item-${index}`}
              onClick={() => {
                document.getElementById(index).scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              {item?.heading}
            </span>{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

//Timeline
const getTimelineDataList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )

    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <li key={index} className="timeline-li">
    //       <span className="timeline-s">{item?.time} : </span>
    //       <span className="timeline-t">{item?.summary}</span>
    //     </li>
    //   );
    // })
  );
};

//Key Info
const getSummaryDataList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // <div className="summary-li">
    //   <span className="summary-text">{data ?? ""} </span>
    // </div>
  );
};

//Label Brief
const getHeadingDataList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <li key={index} className="heading-li">
          <span className="heading-h">{item?.heading}</span>
          <br />
          <span className="heading-s">{item?.summary}</span>
        </li>
      );
    })
  );
};

//Named Entities
const getNERDataList = (data) => {
  return (
    data &&
    data?.map((item, index) => {
      return (
        <div key={index} className="ner-box">
          <span className="ner-text">{item.display_name} </span>
          <hr />
          <div className="ner-sub-box">
            {item?.entities?.map((subItem, subIndex) => {
              return (
                <span key={subIndex} className="ner-sub-text">
                  {subItem}
                </span>
              );
            })}
          </div>
        </div>
      );
    })
  );
};

//Citations
const getCitationsList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <div key={index} className="citation-box">
    //       <span className="citation-d">{item?.document} </span>
    //       <span className="citation-s">{item?.section}</span>
    //     </div>
    //   );
    // })
  );
};

//Cited Examples
const getCitedExampleList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <div key={index} className="citation-box">
    //       <span className="citation-d">{item?.term} : </span>
    //       <span className="citation-s">{item?.example}</span>
    //     </div>
    //   );
    // })
  );
};

//Topics
const getTopicsList = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
    // data &&
    // data?.map((item, index) => {
    //   return (
    //     <div key={index} className="citation-box">
    //       <span className="citation-d">{item?.topic} </span>
    //       <span className="citation-s">{item?.description}</span>
    //     </div>
    //   );
    // })
  );
};

//Info Snippets
const getInfoSnippets = (data) => {
  return (
    data &&
    Object.keys(data)?.map((key, index) => {
      if (data[key]?.length === 0) return null;
      return (
        <div key={index} className="ner-box">
          <span className="ner-text">{key} </span>
          <hr />
          <div className="ner-sub-box">
            {data[key] &&
              data[key]?.map((item, subIndex) => {
                return (
                  <span key={subIndex} className="ner-sub-text">
                    {item}
                  </span>
                );
              })}
          </div>
        </div>
      );
    })
  );
};

const getBusinessInfoLegacy = (data) => {
  return (
    data && (
      <div>
        <MarkdownRenderer markdown={data.toString()} />
      </div>
    )
  );
};
