import React from "react";
import { Modal, ModalBody } from "reactstrap";

const LogoutConfirmModal = ({ isOpen, handleClose, handleConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      // size="md"
      centered
      backdrop
      scrollable
      fade
      className="modal-content-override"
    >
      <ModalBody className="container-modal">
        <h5
          className="are-you-sure-text"
          style={{
            marginBottom: 25,
            fontSize: 20,
          }}
        >
          Are you sure you want to logout?
        </h5>
        <div className="action-button-container">
          <div>
            {" "}
            <button
              className="action-button"
              onClick={() => {
                handleConfirm();
                handleClose();
              }}
            >
              Yes
            </button>
          </div>
          <div>
            <button className="action-button" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LogoutConfirmModal;
