import React from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";

const SubscriptionConfirm = ({
  isOpen,
  handleClose,
  handleConfirm,
  description,
  isLoading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      size="md"
      centered
      backdrop
      scrollable
      fade
      // className="modal-content-override"
    >
      <ModalBody className="container-modal">
        <h5
          className="are-you-sure-text"
          style={{
            marginBottom: 25,
            fontSize: 20,
          }}
        >
          {description ?? ""}
        </h5>
        <div className="action-button-container">
          <div>
            {" "}
            <button
              className="action-button"
              onClick={() => {
                handleConfirm();
              }}
              disabled={isLoading}
            >
              {isLoading && (
                <Spinner
                  color="white"
                  size={"sm"}
                  style={{
                    borderWidth: "2px",
                    alignSelf: "center",
                    marginRight: "10px",
                  }}
                />
              )}{" "}
              Yes
            </button>
          </div>
          <div>
            <button className="action-button" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SubscriptionConfirm;
