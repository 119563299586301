import { Menu } from "lucide-react";
import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  position: fixed;
  height: 100%;
  top: 65px;
  transition: opacity 0.8s ease-in-out;
  width: ${(props) => (props.sidebarStatus ? "190px" : "50px")};
  z-index: 0;
  border-right: 1px solid rgb(226, 226, 226);
  > img {
    position: absolute;
    color: #e2e2e2;
    width: 20px;
    height: 20px;
    top: 22px;
    left: 22px;
    cursor: pointer;
  }
  animation: ${(props) => (props.sidebarStatus ? "showSidebar" : "hideSidebar")}
    0.2s;
  @keyframes showSidebar {
    from {
      width: 50px;
    }
    to {
      width: 190px;
    }
  }

  @keyframes hideSidebar {
    from {
      width: 190px;
    }
    to {
      width: 50px;
    }
  }
`;

export const Content = styled.div`
  overflow: scroll !important;
  height: 100%;
  margin-top: 12px;
`;

export const SidebarIcon = styled(Menu)`
  position: absolute;
  width: 23px;
  height: 23px;
  top: 12px;
  left: 18px;
  padding: 2px;
  margin-left: 2px;
  cursor: pointer;
  transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out !important; /* Width and opacity transition */
  &:hover {
    background-color: #e9e1e1; /* New background color on hover */
    border-radius: 5px; /* Make the background semi-circular on hover */
  }
`;
