import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ConfirmationAlertModal = ({ isOpen, handleClose, title }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleClose(false)}
      // size="md"
      centered
      backdrop
      scrollable
      fade
      className="modal-content-override"
    >
      <ModalBody className="container-modal">
        <h5
          className="are-you-sure-text"
          style={{
            marginBottom: 15,
            fontSize: 20,
            lineHeight: "1",
          }}
        >
          {title ?? "-"}
        </h5>

        <div className="action-button-container">
          <div>
            {" "}
            <button
              className="action-button"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationAlertModal;
